<template>
  <v-row justify="center">
    <v-col>
      <v-simple-table class="bordered" dense>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>Company</td>
              <td>{{ summary_offer_data.company.name }}</td>
            </tr>

            <tr>
              <td>Account</td>
              <td>
                {{
                  summary_offer_data.account
                    ? summary_offer_data.account.type === "Cash"
                      ? "Cash"
                      : summary_offer_data.account.name
                    : ""
                }}
              </td>
            </tr>

            <tr>
              <td>Mortage Amount</td>
              <td>{{ summary_offer_data.mortgage_amount | formatPrice }}</td>
            </tr>

            <tr>
              <td>Mortage Position</td>
              <td>
                {{ summary_offer_data.mortgage_position | mortgagePosition }}
              </td>
            </tr>

            <tr>
              <td>Term (months)</td>
              <td>{{ summary_offer_data.mortgage_term }} Months</td>
            </tr>

            <tr>
              <td>Term Type</td>
              <td>
                {{
                  summary_offer_data.term_type
                    ? summary_offer_data.term_type === "Open - After Months"
                      ? ` Open - After ${
                          summary_offer_data.number_of_months > 1
                            ? `${summary_offer_data.number_of_months} Months`
                            : `${summary_offer_data.number_of_months} Month`
                        }`
                      : `${summary_offer_data.term_type}`
                    : ""
                }}
              </td>
            </tr>

            <tr>
              <td>Interest Rate</td>
              <td>{{ summary_offer_data.interest_rate }} %</td>
            </tr>

            <tr>
              <td>Lender Fee</td>
              <td>{{ summary_offer_data.lender_fee }} %</td>
            </tr>

            <tr>
              <td>Yield</td>
              <td>
                {{
                  summary_offer_data.lender_fee +
                  summary_offer_data.interest_rate
                }}
                %
              </td>
            </tr>

            <tr>
              <td>Comments</td>
              <td>{{ summary_offer_data.comments }}</td>
            </tr>

            <tr>
              <td>Conditions</td>
              <td>{{ summary_offer_data.conditions }}</td>
            </tr>

            <tr>
              <td>Date Created</td>
              <td>{{ summary_offer_data.createdAt | formatDate }}</td>
            </tr>

            <tr>
              <td>Last Update</td>
              <td>{{ summary_offer_data.updatedAt | formatDate }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import filt from "@/plugins/filters";

export default {
  props: ["summary_offer_data"],

  filters: {
    ...filt,
  },
};
</script>

<style lang="scss" scoped>
.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
}
</style>
