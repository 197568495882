<template>
  <v-container>
    <v-row justify="center">
      <v-col xs="12" xl="8">
        <!-- notification area -->
        <div class="sticky-row">
          <v-alert
            v-show="error"
            class="custom-alert"
            transition="fade-transition"
            type="error"
            dense
          >
            <v-row align="center">
              <v-col class="grow">
                {{ errorMessage }}
              </v-col>
              <v-col class="shrink">
                <v-btn title="close" icon>
                  <v-icon @click="error = false"> mdi-close </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>

          <v-alert
            color="orange darken-3"
            v-show="warning"
            transition="fade-transition"
            dense
          >
            <div class="d-flex align-center">
              <v-icon class="mr-2"> mdi-alert-circle-outline </v-icon>
              <span>{{ warningMessage }}</span>

              <v-btn
                class="ml-auto"
                title="Click to close"
                icon
                @click="warning = false"
              >
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </div>
          </v-alert>
        </div>

        <v-row v-if="loading">
          <v-col>
            <h3 class="text-center">
              <v-progress-circular indeterminate color="primary" />
              Please wait...
            </h3>
          </v-col>
        </v-row>

        <v-row v-if="!loading">
          <v-col>
            <v-row>
              <v-col>
                <div class="image-container">
                  <v-img
                    src="@/assets/my_bids.png"
                    :aspect-ratio="aspectRatio"
                    class="darkened-image"
                  />

                  <div class="centered-text">
                    <h1 :class="headerText">My Bid History</h1>
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <p
                  :class="
                    windowWidth >= 600
                      ? 'text-h4 font-weight-medium mb-5'
                      : 'text-h5 font-weight-medium mb-5'
                  "
                >
                  Welcome to my bids
                </p>

                <p
                  :class="
                    windowWidth >= 600
                      ? 'grey--text text-subtitle-1'
                      : 'grey--text text-subtitle-2'
                  "
                >
                  Here you can see a list of deals which you have bid on
                  previously. If a deal is active, you can go back into the deal
                  and make more bids or adjust your existing ones. If your bid
                  was chosen by the borrower, it will be shown as “Won By Me”
                  status. If a bid by another company was chosen, it will show
                  “Won By Others”.
                </p>
              </v-col>
            </v-row>

            <!-- active bids -->
            <v-row>
              <v-col>
                <div class="bordered pa-3 rounded-lg">
                  <div
                    :class="
                      windowWidth >= 600
                        ? 'd-flex align-center justify-space-between'
                        : ''
                    "
                  >
                    <p class="text-caption text-sm-body-1 font-weight-medium">
                      Deal summary list:
                    </p>

                    <p
                      class="text-caption text-sm-body-1 font-weight-medium ml-auto"
                    >
                      My active bid total:
                      <span class="ml-2" style="color: green">
                        {{ formatValue(activeBidTotal, "currency") }}
                      </span>
                    </p>
                  </div>

                  <v-expansion-panels v-model="activePanels" multiple>
                    <v-expansion-panel
                      v-for="dealSummary in this.dealSummaryData"
                      :key="dealSummary.id"
                      class="mt-5 bordered rounded-lg"
                    >
                      <v-expansion-panel-header
                        :style="
                          dealSummary.status === 'active'
                            ? 'color:green'
                            : 'color:gray'
                        "
                      >
                        <span class="text-caption text-sm-body-1">
                          {{ dealSummary.deal && dealSummary.deal.public_name }}
                          - {{ dealSummary.city }} -
                          {{ dealSummary.province }} - LTV:
                          {{ dealSummary.loan_to_value }}% - ({{
                            dealSummary.status
                          }})
                          <v-icon
                            v-if="dealSummary.deal_summary_status == 'active'"
                            class="ml-5"
                            title="preview this deal"
                            @click="previewDeal(dealSummary.id)"
                          >
                            mdi-magnify
                          </v-icon>
                        </span>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>
                        <!-- web view -->
                        <v-data-table
                          v-if="windowWidth >= 960"
                          :headers="headers"
                          :items="dealSummary.summary_offers"
                          :items-per-page="-1"
                          hide-default-footer
                          @click:row="viewOffer(dealSummary, $event)"
                          dense
                        >
                          <template v-slot:[`item.edit`]="{ item }">
                            <span
                              style="color: green; cursor: pointer"
                              v-if="dealSummary.status === 'active'"
                              @click.stop="
                                temp_summary_offer_data = item;
                                temp_deal_summary_data = dealSummary;
                                edit_summary_offer_dialog_activate();
                              "
                            >
                              (edit)
                            </span>
                          </template>

                          <template v-slot:[`item.account`]="{ item }">
                            {{
                              item.account
                                ? item.account.type === "Cash"
                                  ? "Cash"
                                  : item.account.name
                                : ""
                            }}
                          </template>

                          <template v-slot:[`item.term_type`]="{ item }">
                            {{
                              item.term_type
                                ? item.term_type === "Open - After Months"
                                  ? ` Open - After ${
                                      item.number_of_months > 1
                                        ? `${item.number_of_months} Months`
                                        : `${item.number_of_months} Month`
                                    }`
                                  : `${item.term_type}`
                                : ""
                            }}
                          </template>

                          <template v-slot:[`item.interest_rate`]="{ item }">
                            {{ formatValue(item.interest_rate, "percentage") }}
                          </template>

                          <template v-slot:[`item.lender_fee`]="{ item }">
                            {{ formatValue(item.lender_fee, "percentage") }}
                          </template>

                          <template v-slot:[`item.mortgage_amount`]="{ item }">
                            {{ formatValue(item.mortgage_amount, "currency") }}
                          </template>
                        </v-data-table>

                        <!-- mobile view -->
                        <v-card
                          v-for="item in windowWidth < 960
                            ? dealSummary.summary_offers
                            : []"
                          :key="item.id"
                          class="my-5 bordered rounded-lg"
                        >
                          <v-card-title>
                            <v-spacer />
                            <v-menu left offset-x>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                  <v-icon> mdi-dots-vertical </v-icon>
                                </v-btn>
                              </template>
                              <v-list class="pa-0">
                                <v-list-item
                                  link
                                  @click="
                                    temp_summary_offer_data = item;
                                    temp_deal_summary_data = dealSummary;
                                    edit_summary_offer_dialog_activate();
                                  "
                                >
                                  <v-list-item-title>
                                    <v-icon class="mr-2 text-body-1">
                                      mdi-pencil-outline
                                    </v-icon>
                                    Edit
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                  link
                                  @click="viewOffer(dealSummary, item)"
                                >
                                  <v-list-item-title>
                                    <v-icon class="mr-2 text-body-1">
                                      mdi-magnify
                                    </v-icon>
                                    View
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-card-title>

                          <v-card-text>
                            <v-simple-table dense>
                              <template v-slot:default>
                                <tbody>
                                  <tr>
                                    <td
                                      :width="windowWidth > 600 ? '200' : '150'"
                                      class="text-caption"
                                    >
                                      Company
                                    </td>
                                    <td class="text-caption">
                                      {{ item.company && item.company.name }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      :width="windowWidth > 600 ? '200' : '150'"
                                      class="text-caption"
                                    >
                                      Account
                                    </td>
                                    <td class="text-caption">
                                      {{
                                        item.account
                                          ? item.account.type === "Cash"
                                            ? "Cash"
                                            : item.account.name
                                          : ""
                                      }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      :width="windowWidth > 600 ? '200' : '150'"
                                      class="text-caption"
                                    >
                                      Term
                                    </td>
                                    <td class="text-caption">
                                      {{ item.mortgage_term }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td class="text-caption">Term Type</td>
                                    <td class="text-caption">
                                      {{
                                        item.term_type
                                          ? item.term_type ===
                                            "Open - After Months"
                                            ? ` Open - After ${
                                                item.number_of_months > 1
                                                  ? `${item.number_of_months} Months`
                                                  : `${item.number_of_months} Month`
                                              }`
                                            : `${item.term_type}`
                                          : ""
                                      }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td class="text-caption">Interest Rate</td>
                                    <td class="text-caption">
                                      {{
                                        formatValue(
                                          item.interest_rate,
                                          "percentage"
                                        )
                                      }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td class="text-caption">Lender Fee</td>
                                    <td class="text-caption">
                                      {{
                                        formatValue(
                                          item.lender_fee,
                                          "percentage"
                                        )
                                      }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td class="text-caption">Amount</td>
                                    <td class="text-caption">
                                      {{
                                        formatValue(
                                          item.mortgage_amount,
                                          "currency"
                                        )
                                      }}%
                                    </td>
                                  </tr>

                                  <tr>
                                    <td class="text-caption">Comments</td>
                                    <td class="text-caption">
                                      {{ item.comments }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td class="text-caption">Conditions</td>
                                    <td class="text-caption">
                                      {{ item.conditions }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td class="text-caption">Status</td>
                                    <td class="text-caption">
                                      {{ item.status }}
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-card-text>

                          <v-card-actions>
                            <!-- you can add more actions here -->
                          </v-card-actions>
                        </v-card>

                        <div class="d-flex justify-end">
                          <v-btn
                            text
                            title="Go to deal summary page"
                            color="primary"
                            class="mt-0 mt-md-2"
                            :small="windowWidth < 600"
                            @click="
                              $router.push({
                                path: `/dashboard/mortgagesAvailable/read/${dealSummary.id}`,
                              })
                            "
                          >
                            <v-icon> mdi-magnify </v-icon>
                            view deal
                          </v-btn>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </v-col>
            </v-row>

            <!-- won bids -->
            <v-row class="mt-10">
              <v-col>
                <div class="bordered pa-3 rounded-lg">
                  <div
                    :class="
                      windowWidth >= 600
                        ? 'd-flex align-center justify-space-between'
                        : ''
                    "
                  >
                    <p class="text-caption text-sm-body-1 font-weight-medium">
                      Won bids list:
                    </p>

                    <p
                      class="text-caption text-sm-body-1 font-weight-medium ml-auto"
                    >
                      Won bid total:
                      <span class="ml-2" style="color: green">
                        {{ formatValue(wonBidTotal, "currency") }}
                      </span>
                    </p>
                  </div>

                  <v-expansion-panels v-model="wonPanel" multiple>
                    <v-expansion-panel
                      v-for="dealSummary in this.wonDealSummaryData"
                      :key="dealSummary.id"
                      class="mt-5 bordered rounded-lg"
                    >
                      <v-expansion-panel-header
                        class="text-caption text-sm-body-1"
                      >
                        {{ dealSummary.deal.public_name }} -
                        {{ dealSummary.city }} -
                        {{ dealSummary.deal.province }} - LTV:
                        {{ dealSummary.loan_to_value }}%
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>
                        <!-- web view -->
                        <v-data-table
                          v-if="windowWidth >= 960"
                          :headers="headers"
                          :items="dealSummary.summary_offers"
                          :items-per-page="-1"
                          hide-default-footer
                          dense
                        >
                          <template v-slot:[`item.mortgage_term`]="{ item }">
                            {{ item.mortgage_term }}
                          </template>
                          
                          <template v-slot:[`item.account`]="{ item }">
                            {{
                              item.account
                                ? item.account.type === "Cash"
                                  ? "Cash"
                                  : item.account.name
                                : ""
                            }}
                          </template>

                          <template v-slot:[`item.term_type`]="{ item }">
                            {{
                              item.term_type
                                ? item.term_type === "Open - After Months"
                                  ? ` Open - After ${
                                      item.number_of_months > 1
                                        ? `${item.number_of_months} Months`
                                        : `${item.number_of_months} Month`
                                    }`
                                  : `${item.term_type}`
                                : ""
                            }}
                          </template>

                          <template v-slot:[`item.interest_rate`]="{ item }">
                            {{ formatValue(item.interest_rate, "percentage") }}
                          </template>

                          <template v-slot:[`item.lender_fee`]="{ item }">
                            {{ formatValue(item.lender_fee, "percentage") }}
                          </template>

                          <template v-slot:[`item.mortgage_amount`]="{ item }">
                            {{ formatValue(item.mortgage_amount, "currency") }}
                          </template>
                        </v-data-table>

                        <!-- mobile view -->
                        <v-card
                          v-for="item in windowWidth < 960
                            ? dealSummary.summary_offers
                            : []"
                          :key="item.id"
                          class="my-5 bordered rounded-lg"
                        >
                          <v-card-title>
                            <v-spacer />

                            <!-- add menu for more actions -->
                          </v-card-title>

                          <v-card-text>
                            <v-simple-table dense>
                              <template v-slot:default>
                                <tbody>
                                  <tr>
                                    <td
                                      :width="windowWidth > 600 ? '200' : '150'"
                                      class="text-caption"
                                    >
                                      Company
                                    </td>
                                    <td class="text-caption">
                                      {{ item.company && item.company.name }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      :width="windowWidth > 600 ? '200' : '150'"
                                      class="text-caption"
                                    >
                                      Account
                                    </td>
                                    <td class="text-caption">
                                      {{
                                        item.account
                                          ? item.account.type === "Cash"
                                            ? "Cash"
                                            : item.account.name
                                          : ""
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      :width="windowWidth > 600 ? '200' : '150'"
                                      class="text-caption"
                                    >
                                      Term
                                    </td>
                                    <td class="text-caption">
                                      {{ item.mortgage_term }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td class="text-caption">Term Type</td>
                                    <td class="text-caption">
                                      {{
                                        item.term_type
                                          ? item.term_type ===
                                            "Open - After Months"
                                            ? ` Open - After ${
                                                item.number_of_months > 1
                                                  ? `${item.number_of_months} Months`
                                                  : `${item.number_of_months} Month`
                                              }`
                                            : `${item.term_type}`
                                          : ""
                                      }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td class="text-caption">Interest Rate</td>
                                    <td class="text-caption">
                                      {{
                                        formatValue(
                                          item.interest_rate,
                                          "percentage"
                                        )
                                      }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td class="text-caption">Lender Fee</td>
                                    <td class="text-caption">
                                      {{
                                        formatValue(
                                          item.lender_fee,
                                          "percentage"
                                        )
                                      }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td class="text-caption">Amount</td>
                                    <td class="text-caption">
                                      {{
                                        formatValue(
                                          item.mortgage_amount,
                                          "currency"
                                        )
                                      }}%
                                    </td>
                                  </tr>

                                  <tr>
                                    <td class="text-caption">Comments</td>
                                    <td class="text-caption">
                                      {{ item.comments }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td class="text-caption">Conditions</td>
                                    <td class="text-caption">
                                      {{ item.conditions }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td class="text-caption">Status</td>
                                    <td class="text-caption">
                                      {{ item.status }}
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-card-text>

                          <v-card-actions>
                            <!-- you can add more actions here -->
                          </v-card-actions>
                        </v-card>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </v-col>
            </v-row>

            <!-- pop up dialogs -->

            <v-dialog
              v-model="view_full_offer_dialog"
              fullscreen
              hide-overlay
              transition="slide-x-transition"
            >
              <v-card v-if="view_full_offer_dialog">
                <v-toolbar class="orange darken-3">
                  <v-btn
                    title="close"
                    icon
                    @click="view_full_offer_dialog = false"
                  >
                    <v-icon> mdi-close </v-icon>
                  </v-btn>

                  <v-toolbar-title>Offer Details</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="mt-10">
                  <FullOfferDetailsDialog
                    :summary_offer_data="temp_summary_offer_data"
                  />
                </v-card-text>

                <v-card-actions>
                  <v-btn
                    title="Edit"
                    @click="
                      view_full_offer_dialog = false;
                      edit_summary_offer_dialog_activate();
                    "
                    color="teal"
                  >
                    <v-icon class="mr-2 text-subtitle-1">
                      mdi-pencil-outline
                    </v-icon>
                    edit offer
                  </v-btn>

                  <v-btn
                    title="Close"
                    @click="
                      temp_summary_offer_data = null;
                      temp_deal_summary_data = null;
                      view_full_offer_dialog = false;
                      edit_summary_offer_dialog = false;
                      show_add_offer_dialog = false;
                      getData();
                    "
                    color="error"
                  >
                    close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <Add_Deal_Summary_Offer
              ref="offer_dialog"
              v-if="show_add_offer_dialog"
              :company="company"
              :deal_summary_data="temp_deal_summary_data"
              view_type="logged_in"
              :dialog="show_add_offer_dialog"
              :from_my_bids="true"
              @close="
                show_add_offer_dialog = false;
                edit_summary_offer_data = null;
                edit_summary_offer_dialog = false;
                getData();
              "
              :edit_summary_offer_dialog="edit_summary_offer_dialog"
              :edit_summary_offer_data="edit_summary_offer_data"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";
import Add_Deal_Summary_Offer from "@/components/Add_Deal_Summary_Offer";
import FullOfferDetailsDialog from "@/components/FullOfferDetailsDialog";
import filt from "@/plugins/filters";

export default {
  components: {
    Add_Deal_Summary_Offer,
    FullOfferDetailsDialog,
  },

  data: () => ({
    loading: true,
    error: false,
    errorMessage: "",
    warning: false,
    warningMessage: "",

    activePanels: [0],
    wonPanel: [0],

    company: null,

    dealSummaryData: [],
    wonDealSummaryData: [],

    // for editing offer dialog
    temp_deal_summary_data: null,
    edit_summary_offer_dialog: false,
    edit_summary_offer_data: null,
    show_add_offer_dialog: false,

    // for viewing full offer dialog
    temp_summary_offer_data: null,
    view_full_offer_dialog: false,

    headers: [
      { text: "Company", value: "company.name" },
      { text: "Account", value: "account" },
      { text: "Term", value: "mortgage_term" },
      { text: "Term Type", value: "term_type" },
      { text: "Interest rate", value: "interest_rate" },
      { text: "Lender fee", value: "lender_fee" },
      { text: "Amount", value: "mortgage_amount" },
      { text: "Comments", value: "comments" },
      { text: "Conditions", value: "conditions" },
      { text: "Status", value: "status" },
      { text: "", value: "edit" },
    ],

    windowWidth: window.innerWidth,
  }),

  computed: {
    activeBidTotal() {
      let totalSum = 0;

      for (const summaryData of this.dealSummaryData) {
        let maxMortgageAmount = 0;

        for (const offer of summaryData.summary_offers) {
          const mortgageAmount = parseFloat(offer.mortgage_amount);
          if (mortgageAmount > maxMortgageAmount) {
            maxMortgageAmount = mortgageAmount;
          }
        }

        totalSum += maxMortgageAmount;
      }

      return totalSum;
    },

    wonBidTotal() {
      let totalSum = 0;

      for (const summaryData of this.wonDealSummaryData) {
        let maxMortgageAmount = 0;

        for (const offer of summaryData.summary_offers) {
          const mortgageAmount = parseFloat(offer.mortgage_amount);
          if (mortgageAmount > maxMortgageAmount) {
            maxMortgageAmount = mortgageAmount;
          }
        }

        totalSum += maxMortgageAmount;
      }

      return totalSum;
    },

    aspectRatio() {
      if (this.windowWidth >= 1264) {
        return 3;
      } else if (this.windowWidth >= 960) {
        return 2.5;
      } else if (this.windowWidth >= 600) {
        return 2;
      } else {
        return 1.5;
      }
    },

    headerText() {
      if (this.windowWidth >= 960) {
        return "text-center text-h3 font-weight-black";
      } else {
        return "text-center text-h4 font-weight-black";
      }
    },
  },

  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },

    previewDeal(value) {
      this.$router.push(`/dashboard/mortgagesAvailable/read/${value}`);
    },

    viewOffer(deal_summary, offer) {
      this.temp_deal_summary_data = deal_summary;
      this.temp_summary_offer_data = offer;
      this.view_full_offer_dialog = true;
    },

    formatValue(value, format) {
      switch (format) {
        case "currency":
          return this.$options.filters.formatPrice(String(value));
        case "percentage":
          return value + "%";
        default:
          return value;
      }
    },

    edit_summary_offer_dialog_activate() {
      this.temp_summary_offer_data.mortgage_amount =
        this.$options.filters.formatPrice(
          String(this.temp_summary_offer_data.mortgage_amount)
        );

      this.edit_summary_offer_data = this.temp_summary_offer_data;
      this.edit_summary_offer_dialog = true;
      this.show_add_offer_dialog = true;
      this.$nextTick(() => {
        this.$refs.offer_dialog.initialize_data();
      });
    },

    async getData() {
      try {
        this.loading = true;

        this.company = this.$store.getters["Auth/getAuthUser"].companies[0];

        if (this.company) {
          const res = await API().get(
            `/api/user_deal_summaries_my_bids?company_id=${this.company.id}`
          );

          if (res.data) {
            this.dealSummaryData = res.data.deal_summaries_with_my_active_bids;
            this.wonDealSummaryData = res.data.deal_summaries_my_won_bids;

            const no_deal_in_active_bids = this.dealSummaryData.some(
              (el) => !el.deal
            );

            if (no_deal_in_active_bids) {
              this.error = true;
              this.errorMessage =
                "Some of the deal summary in the active bids are missing deal data. Please contact the administrator.";
            }

            const no_deal_in_won_bids = this.wonDealSummaryData.some(
              (el) => !el.deal
            );

            if (no_deal_in_won_bids) {
              this.error = true;
              this.errorMessage =
                "Some of the deal summary in the won bids are missing deal data. Please contact the administrator.";
            }

            window.addEventListener("resize", this.handleResize);

            this.loading = false;
          } else {
            console.log("no records found");
            this.error = true;
            this.errorMessage = "no records found";
            this.loading = false;
          }
        } else {
          this.warning = true;
          this.warningMessage = "No company found for this user";
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
        console.log(error);
        this.error = true;
        this.errorMessage = "Error Message" + error.response.data.message;
      }
    },
  },

  mounted() {
    this.getData();
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },

  filters: {
    ...filt,
  },
};
</script>

<style>
.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
}

.image-container {
  position: relative;
}

.darkened-image {
  filter: brightness(50%);
}

.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ff6633;
  font-size: 1.5em;
  opacity: 0.7;
  font-weight: bold;
}

.custom-alert .v-icon {
  align-self: center;
}

.sticky-row {
  position: sticky;
  top: 64px;
  z-index: 2;
}
</style>
